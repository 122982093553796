import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { GatsbyImageProps } from 'gatsby-image';

import Layout from 'molecules/Layout';
import Datenschutz from 'organisms/Datenschutz';

import 'styles/pages/datenschutz.scss';

const DATENSCHUTZ_QUERY = graphql`
  query DatenschutzQuery {
    logo: file(relativePath: { eq: "logos/thamarai-datenschutz-logo.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 375, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    site {
      siteMetadata {
        telephone
        email
        siteUrl
      }
    }
  }
`;

interface Data {
  logo: {
    childImageSharp: GatsbyImageProps;
  };
  site: {
    siteMetadata: {
      telephone: string;
      email: string;
      siteUrl: string;
    };
  };
}

interface Props {}

const DatenschutzPage: React.FC<Props> = () => {
  const {
    site: { siteMetadata },
    logo,
  } = useStaticQuery<Data>(DATENSCHUTZ_QUERY);

  return (
    <Layout
      searchEngineOptimization={() => (
        <Layout.SEO
          path="/datenschutz"
          canonical="/datenschutz"
          title="Datenschutz"
          lang="de-DE"
        />
      )}
      header={() => (
        <Layout.Header
          title="Datenschutzerklärung"
          subtitle="Im vollen Einklang mit DSGVO"
          image={logo.childImageSharp}
          alt="Exklusives Logo für die Datenschutz-Seite"
        />
      )}
    >
      <Datenschutz {...siteMetadata} />
    </Layout>
  );
};
export default DatenschutzPage;
