import React from 'react';

interface Props {
  telephone: string;
  email: string;
  siteUrl: string;
}

const Datenschutz: React.FC<Props> = ({ siteUrl, email, telephone }) => {
  return (
    <div className={`Datenschutz`}>
      <p>
        Wir haben das Ziel die hohen Standards der neuen Datenschutzverordnung
        der Europäischen Union (Allgemeine Datenschutzverordnung = EU-DSGVO) zu
        erfüllen. Ab dem 25. Mai 2018 gelten die neuen Allgemeinen
        Datenschutzverordnungen der EU. Nachfolgend wird klargestellt, wie wir
        Ihre personenbezogenen Daten erheben und verarbeiten, zu welchen Zwecken
        wir Ihre Daten verwenden, welche rechtlichen Gründe für die Verarbeitung
        dieser Daten bestehen, wie wir Ihre Daten sicher halten und welche
        Rechte Sie in Bezug auf diese Daten haben.
      </p>
      <p>
        Der Nutzung von im Rahmen der Impressumspflicht veröffentlichten
        Kontaktdaten durch Dritte zur Übersendung von nicht ausdrücklich
        angeforderter Werbung wird hiermit widersprochen. Als Anbieter der
        Seiten behalten wir uns ausdrücklich rechtliche Schritte gegen die
        Versendung von Spam-Mails und ähnlichen Werbeinformationen vor.
      </p>
      <p>
        Quelle:&nbsp;
        <a href="https://www.streifler.de">S&K Rechtsanwälte</a>
      </p>
      <h3>Einführung</h3>
      <p>
        In dieser Datenschutzerklärung erfahren Sie, wie wir Ihre
        personenbezogenen Daten durch die Nutzung unserer Website {siteUrl}{' '}
        sammlen und verarbeiten.
      </p>
      <div className="Datenschutz__juristic">
        <div>Vollständiger Name der juristischen Person:</div>
        <div>Rathika Soosaithasan</div>
        <div>Horkheimer Straße 29</div>
        <div>74081 Heilbronn</div>
        <div>E-Mail: {email}</div>
        <div>Phone: {telephone}</div>
      </div>
      <p>
        Welche daten erfassen wir über sie, zu welchem Zweck und auf welchem
        Grund verarbeiten wir Sie? Personenbezogene Daten sind alle
        Informationen, mit denen sich eine Person identifizieren lässt. Es
        enthält keine anonymen oder sensiblen Daten.
      </p>
      <p>
        Wir können die folgenden Kategorien personenbezogener Daten über Sie
        verarbeiten:
      </p>
      <p>
        Kommunikationsdaten, die jegliche Kommunikation umfassen, die Sie uns
        über das Kontaktformular auf unserer Website, über E-Mail, Text, Social
        Media Messaging, Social Media Posting oder jede andere Kommunikation,
        die Sie uns senden, senden. Ich verarbeite diese Daten, um mit Ihnen zu
        kommunizieren, Aufzeichnungen zu führen und um rechtliche Ansprüche
        geltend zu machen, zu verfolgen oder zu verteidigen. Unser rechtmäßiger
        Grund für diese Verarbeitung sind unsere berechtigten Interessen, die in
        diesem Fall darin bestehen, auf an uns gerichtete Mitteilungen zu
        antworten, Aufzeichnungen zu führen und rechtliche Ansprüche zu
        begründen, zu verfolgen oder zu verteidigen.
      </p>
      <p>
        Kundendaten, einschließlich Daten zu Käufen von Produkten /
        Veranstaltungen und / oder Dienstleistungen, wie Name, Rechnungsadresse,
        Lieferadresse, E-Mail-Adresse, Telefonnummer (optional), Kontaktdaten,
        Kaufdetails und Kartendaten. Ich verarbeite diese Daten, um die von
        Ihnen gekauften Produkte / Veranstaltungen und / oder Dienstleistungen
        bereitzustellen und Aufzeichnungen über solche Transaktionen zu führen.
        Unser rechtmäßiger Grund für diese Verarbeitung ist die Erfüllung eines
        Vertrags zwischen Ihnen und uns und / oder die Ergreifung von Schritten
        auf Ihren Wunsch hin, einen solchen Vertrag abzuschließen.
      </p>
      <p>
        Benutzerdaten, die Daten darüber enthalten, wie Sie unsere Website und
        Online-Dienste nutzen, sowie Daten, die Sie zur Veröffentlichung auf
        unserer Website oder über andere Online-Dienste bereitstellen. Ich
        verarbeite diese Daten, um unsere Website zu betreiben und
        sicherzustellen, dass Ihnen relevante Inhalte zur Verfügung gestellt
        werden, um die Sicherheit unserer Website zu gewährleisten, um Backups
        unserer Website und / oder Datenbanken zu erstellen und um die
        Veröffentlichung und Verwaltung unserer Website sowie anderer
        Onlinedienste zu ermöglichen und Geschäft. Unser rechtmäßiger Grund für
        diese Verarbeitung sind unsere berechtigten Interessen, die es uns in
        diesem Fall ermöglichen, unsere Website und unser Geschäft ordnungsgemäß
        zu verwalten.
      </p>
      <p>
        Technische Daten, die Daten über Ihre Nutzung unserer Website und
        Onlinedienste wie Ihre IP-Adresse, Ihre Anmeldedaten und Angaben zu
        Ihrem Browser enthalten, werden nur zur Verwaltung und zum Schutz
        unseres Geschäfts und unserer Website verarbeitet, um relevante
        Website-Inhalte bereitzustellen. Andere Tracking-Tools werden nicht
        angewendet. Unser rechtmäßiger Grund für diese Verarbeitung sind unsere
        berechtigten Interessen, die in diesem Fall die ordnungsgemäße
        Verwaltung unserer Website ermöglichen sollen.
      </p>
      <h3>Google Analytics</h3>
      <p>
        Wir verwenden auf unserer Website den Webanalysedienst Google Analytics
        der Google LLC. (1600 Amphitheatre Parkway, Mountain View, CA 94043,
        USA; „Google“). Soweit Sie Ihren gewöhnlichen Aufenthalt im Europäischen
        Wirtschaftsraum oder der Schweiz haben, ist Google Ireland Limited
        (Gordon House, Barrow Street, Dublin 4, Irland) der für Ihre Daten
        zuständige Verantwortliche. Google Ireland Limited ist demnach das mit
        Google verbundene Unternehmen, welches für die Verarbeitung Ihrer Daten
        und die Einhaltung der anwendbaren Datenschutzgesetze verantwortlich
        ist.
      </p>
      <p>
        Die Datenverarbeitung dient dem Zweck der Analyse dieser Website und
        ihrer Besucher. Dazu wird Google im Auftrag des Betreibers dieser
        Website die gewonnenen Informationen benutzen, um Ihre Nutzung der
        Website auszuwerten, um Reports über die Websiteaktivitäten
        zusammenzustellen und um weitere, mit der Websitenutzung und der
        Internetnutzung verbundene Dienstleistungen gegenüber dem
        Websitebetreiber zu erbringen.
      </p>
      <p>
        Die im Rahmen von Google Analytics von Ihrem Browser übermittelte
        IP-Adresse wird nicht mit anderen Daten von Google zusammengeführt.
        Google Analytics verwendet Cookies, die eine Analyse der Benutzung der
        Website durch Sie ermöglichen. Die durch die Cookies erzeugten
        Informationen über Ihre Benutzung dieser Website werden in der Regel an
        einen Server von Google in den USA übertragen und dort gespeichert.
      </p>
      <p>
        Auf dieser Website ist die IP-Anonymisierung aktiviert. Dadurch wird
        Ihre IP-Adresse von Google innerhalb von Mitgliedstaaten der
        Europäischen Union oder in anderen Vertragsstaaten des Abkommens über
        den Europäischen Wirtschaftsraum zuvor gekürzt. Nur in Ausnahmefällen
        wird die volle IP-Adresse an einen Server von Google in den USA
        übertragen und dort gekürzt. Google hat sich nach dem
        US-EU-Datenschutzabkommen “Privacy Shield” zertifiziert und damit
        verpflichtet, die europäischen Datenschutzrichtlinien einzuhalten.
      </p>
      <p>
        Sie können die Erfassung der durch das Cookie erzeugten und auf Ihre
        Nutzung der Website bezogenen Daten (inkl. Ihrer IP-Adresse) an Google
        sowie die Verarbeitung dieser Daten durch Google verhindern, indem Sie
        das unter dem{' '}
        <a href="https://tools.google.com/dlpage/gaoptout?hl=de">
          folgenden Link
        </a>{' '}
        verfügbare Browser-Plug-in herunterladen und installieren. Um die
        Datenerfassung und -speicherung durch Google Analytics
        geräteübergreifend zu verhindern, können Sie einen Opt-Out-Cookie
        setzen. Opt-Out-Cookies verhindern die zukünftige Erfassung Ihrer Daten
        beim Besuch dieser Website. Sie müssen das Opt-Out auf allen genutzten
        Systemen und Geräten durchführen, damit dies umfassend wirkt. Wenn Sie
        das Opt-out-Cookie löschen, werden wieder Anfragen an Google
        übermittelt.
      </p>
      <p>
        Nähere Informationen zu Nutzungsbedingungen und Datenschutz finden Sie
        unter https://www.google.com/analytics/terms/de.html bzw. unter
        https://www.google.de/intl/de/policies/.
      </p>
      <h3>Datensicherheit</h3>

      <p>
        Wir haben Sicherheitsmaßnahmen getroffen, um zu verhindern, dass Ihre
        persönlichen Daten versehentlich verloren gehen, verwendet, geändert,
        offengelegt werden oder ohne Genehmigung abgerufen werden.
      </p>
      <p>
        Wir haben Verfahren eingerichtet, um mutmaßlichen Verstößen gegen
        personenbezogene Daten zu begegnen, und werden Sie und die zuständigen
        Aufsichtsbehörden über Verstöße benachrichtigen, wenn dies gesetzlich
        vorgeschrieben ist.
      </p>
      <h3>Vorratsdatenspeicherung</h3>
      <p>
        Wir werden Ihre personenbezogenen Daten nur so lange aufbewahren, wie
        dies zur Erfüllung der Zwecke, für die wir sie gesammelt habe,
        erforderlich ist.
      </p>
      <p>
        Bei der Entscheidung, wie lange die Daten aufzubewahren sind, schauen
        wir auf Menge, Art und Sensibilität, potenzielles Schadensrisiko durch
        unbefugte Nutzung oder Weitergabe sowie auf die Verarbeitungszwecke,
        sofern diese durch andere Mittel und gesetzliche Anforderungen erreicht
        werden können.
      </p>
      <p>
        Aus steuerlichen Gründen sind wir gesetzlich verpflichtet, grundlegende
        Informationen über unsere Kunden (einschließlich Kontakt-, Identitäts-,
        Finanz- und Transaktionsdaten) sieben Jahre lang aufzubewahren, nachdem
        sie nicht mehr Kunden sind.
      </p>
      <h3>Ihre Rechte</h3>
      <p>
        Nach dem Datenschutzgesetz haben Sie Rechte in Bezug auf Ihre
        personenbezogenen Daten, die das Recht einschließen, Zugang,
        Berichtigung, Löschung, Einschränkung, Übermittlung, Widerspruch gegen
        die Verarbeitung, Übertragbarkeit von Daten und (sofern der rechtmäßige
        Grund für die Verarbeitung die Zustimmung ist) zu verlangen Einwilligung
        widerrufen.
      </p>
      <p>
        Wenn Sie eines der oben genannten Rechte ausüben möchten, senden Sie uns
        bitte eine E-Mail an {email} mit dem Betreff "Datenschutz".
      </p>
      <p>
        Möglicherweise müssen wir bestimmte Informationen von Ihnen anfordern,
        um Ihre Identität zu bestätigen und sicherzustellen, dass Sie auf Ihre
        persönlichen Daten zugreifen können (oder andere Rechte ausüben können).
        Dies ist eine Sicherheitsmaßnahme, um sicherzustellen, dass
        personenbezogene Daten nicht an Personen weitergegeben werden, die kein
        Recht darauf haben, sie zu erhalten. Ich kann Sie auch kontaktieren, um
        Sie um weitere Informationen zu Ihrer Anfrage zu bitten, um unsere
        Antwort zu beschleunigen.
      </p>
      <p>
        Wenn Sie mit einem Aspekt der Erhebung und Verwendung Ihrer Daten nicht
        zufrieden sind, wären wir Ihnen dankbar, wenn Sie uns zuerst
        kontaktieren würden, wenn Sie eine Beschwerde haben, damit wir versuchen
        können, diese für Sie zu lösen.
      </p>
      <h3>Links von Drittanbietern</h3>
      <p>
        Diese Website kann Links zu Websites, Plug-ins und Anwendungen von
        Drittanbietern enthalten. Wenn Sie auf diese Links klicken oder diese
        Verbindungen aktivieren, können Dritte möglicherweise Daten über Sie
        sammeln oder weitergeben. Wir kontrollieren diese Websites von
        Drittanbietern nicht und sind nicht für deren Datenschutzerklärungen
        verantwortlich. Wenn Sie unsere Website verlassen, empfehlen wir Ihnen,
        die Datenschutzbestimmungen aller von Ihnen besuchten Websites zu lesen.
      </p>
      <h3>Cookies</h3>
      <p>
        Sie können Ihren Browser so einstellen, dass er alle oder einige
        Browser-Cookies ablehnt oder Sie benachrichtigt, wenn Websites Cookies
        setzen oder auf Cookies zugreifen. Wenn Sie Cookies deaktivieren oder
        ablehnen, beachten Sie bitte, dass einige Teile dieser Website
        möglicherweise nicht mehr zugänglich sind oder nicht mehr ordnungsgemäß
        funktionieren.
      </p>
    </div>
  );
};

export default Datenschutz;
